<template>
	<div ref="video" class="video"></div>
</template>

<script setup lang="ts">
	const props = withDefaults(defineProps<YouTubeEmbedOptions>(), {
		autoplay: false,
		controls: true,
		fullscreen: false,
		loop: false,
		mute: false,
	})

	const { onLoaded } = useScriptYouTubePlayer({})

	const video = ref<HTMLElement>(null!)

	const player = ref<YT.Player>()

	const ready = ref(false)

	onLoaded(async (instance) => {
		const YouTube = await instance.YT

		await new Promise<void>((resolve) => {
			if (typeof YouTube.Player === 'undefined') YouTube.ready(resolve)
			else resolve()
		})

		ready.value = true
	})

	onMounted(() => {
		const url = new URL(props.src)

		const videoId =
			url.pathname === '/watch'
				? (url.searchParams.get('v') ?? undefined)
				: url.pathname.split('/').pop()

		if (videoId) {
			initPlayer(videoId)
			watch([ready, videoId], () => initPlayer(videoId))
		}
	})

	function initPlayer(videoId: string) {
		if (!ready.value) return

		player.value = new YT.Player(video.value, {
			videoId,
			host: 'https://www.youtube-nocookie.com',
			playerVars: {
				autoplay: props.autoplay ? 1 : 0,
				controls: props.controls ? 1 : 0,
				disablekb: 1,
				enablejsapi: 1,
				fs: props.fullscreen ? 1 : 0,
				iv_load_policy: 3,
				loop: props.loop ? 1 : 0,
				modestbranding: 1,
				mute: props.mute ? 1 : 0,
				origin: window.location.origin,
				playsinline: 1,
				rel: 0,
				showinfo: 0,
			},
		})
	}

	function play() {
		player.value?.playVideo()
	}

	function pause() {
		player.value?.pauseVideo()
	}

	function seekTo(time: number) {
		player.value?.seekTo(time, true)
	}

	function toggle() {
		if (player.value?.getPlayerState() === YT.PlayerState.PLAYING) {
			pause()
		} else {
			play()
		}
	}

	defineExpose({
		play,
		pause,
		seekTo,
		toggle,
	})
</script>

<script lang="ts">
	export interface YouTubeEmbedOptions {
		autoplay?: boolean
		controls?: boolean
		fullscreen?: boolean
		loop?: boolean
		mute?: boolean
		src: string
		width?: number | string
		height?: number | string
	}
</script>

<style lang="scss" scoped>
	@layer components {
		.video {
			aspect-ratio: 16/9;
			width: 100%;
			height: auto;
			background-color: black;
		}
	}
</style>
