<template>
	<twic-video
		ref="el"
		:anchor
		class="video"
		:duration
		:eager
		:focus
		:from
		:intrinsic
		:mode
		:placeholder
		:position
		:poster-from
		:ratio
		:src
		:title
		:to />
</template>

<script lang="ts" setup>
	const props = defineProps<VideoOptions>()

	const el = ref<any>(null!)

	const playerEl = ref<HTMLVideoElement>()

	const intrinsic =
		props.width && props.height ? `${props.width}x${props.height}` : null

	const ratio = computed(() => {
		if (props.ratio) {
			return props.ratio
		}

		if (props.width && props.height) {
			return `${props.width}:${props.height}`
		}

		return null
	})

	const src = computed(() => {
		if (!props.src) return

		let path = props.src

		const map = {
			'/': 'public/',
			'https://storage.googleapis.com/cms.tepari.com/': 'cms/',
		}

		for (const [key, value] of Object.entries(map)) {
			if (path.startsWith(key)) {
				path = path.replace(key, value)
			}
		}

		return `https://f7gh3hsz.twic.pics/videos/${path}`
	})

	onMounted(() => {
		const containerEl = el.value?.$el as HTMLElement | undefined
		const videoEl = containerEl?.querySelector('video')

		if (!videoEl) return

		videoEl.addEventListener('loadedmetadata', () => {
			const controls: string[] = ['nodownload']

			if (!props.fullscreen) controls.push('nofullscreen')

			videoEl.autoplay = props.autoplay
			videoEl.controls = props.controls
			videoEl.disablePictureInPicture = true
			videoEl.disableRemotePlayback = true

			videoEl.setAttribute('controlslist', controls.join(' '))
		})

		playerEl.value = videoEl
	})

	function play() {
		playerEl.value?.play()
	}

	function pause() {
		playerEl.value?.pause()
	}

	function seekTo(time: number) {
		if (!playerEl.value) return

		playerEl.value.play()
		playerEl.value.currentTime = time
	}

	function toggle() {
		if (playerEl.value?.paused) {
			play()
		} else {
			pause()
		}
	}

	defineExpose({
		play,
		pause,
		seekTo,
		toggle,
	})
</script>

<script lang="ts">
	export interface VideoOptions {
		/**
		 * Positions the image in both contain and cover mode.
		 */
		anchor?:
			| 'top'
			| 'bottom'
			| 'left'
			| 'right'
			| 'top-left'
			| 'top-right'
			| 'bottom-left'
			| 'bottom-right'
			| 'center'

		/**
		 * Determines if the video should start playing automatically.
		 */
		autoplay?: boolean

		/**
		 * Determines if the video should have controls.
		 */
		controls?: boolean

		/**
		 * Limits the duration of the video.
		 * Duration is expressed in seconds and must be a positive number.
		 */
		duration?: string | number

		/**
		 * Load the image as soon as the component is mounted.
		 * This effectively means disabling lazy loading for this image.
		 */
		eager?: boolean

		/**
		 * Sets the focus point in cover mode. focus takes precedence over anchor when both are provided.
		 */
		focus?: '<auto|coordinates>'

		/**
		 * Determines if the video should allow in fullscreen mode.
		 */
		fullscreen?: boolean

		/**
		 * Moves the starting point of the video.
		 * From is expressed in seconds and must be a positive number.
		 */
		from?: string | number

		/**
		 * The original height of the image.
		 */
		height?: number | `${number}`

		/**
		 * Determines if the image fills the area and is cropped accordingly (cover)
		 * or if the image will sit inside the area with no cropping (contain).
		 */
		mode?: 'contain' | 'cover'

		/**
		 * The placeholder to use while the image is loading.
		 */
		placeholder?: 'preview' | 'maincolor' | 'meancolor' | 'none'

		/**
		 * Positions the image in contain mode.
		 * position takes precedence over anchor when both are provided.
		 * Syntax is the same as for CSS position properties background-position and object-position.
		 */
		position?: string

		/**
		 * Determines which frame of the source video should be used as a poster / preview.
		 * posterFrom is expressed in seconds and must be a positive number.
		 */
		posterFrom?: string | number

		/**
		 * The intrinsic size of the image.
		 */
		ratio?: 'none' | `${number}:${number}` | `${number}/${number}`

		/**
		 * The URL of the image.
		 */
		src: string

		/**
		 * title representing information related to the video
		 */
		title?: string

		/**
		 * Moves the end point of the video.
		 * to is expressed in seconds and must be a positive number.
		 */
		to?: string | number

		/**
		 * The original width of the image.
		 */
		width?: number | `${number}`
	}
</script>

<style lang="scss" scoped>
	@layer components {
		.video {
			:deep(video ~ *) {
				pointer-events: none !important;
			}
		}
	}
</style>
